<template>
  <div>
    <div class="card mb-1">
      <div class="card-body py-1">
        <div class="text-right">
          <h4 class="mb-0">
            <feather-icon icon="CalendarIcon" /> {{ $t('messages.tableHeader.date') }}: {{ today }}
          </h4>
        </div>
      </div>
    </div>
    <Statistics v-if="statisticsData"
                :statistics-data="statisticsData"
    />
    <BOverlay v-if="!hasNoAssignment"
              :show="isProcessing"
    >
      <b-tabs
        pills
        class="lesson-tabs"
      >
        <b-tab
          :title="$t('main-lessons')"
        >
          <b-row>
            <template v-if="studentAssignment.length === 0 && classRoomAssignment.length === 0 && classTestAssignment.length === 0">
              <b-col
                class="no-lesson-small"
              >
                <b-card>
                  {{ $t('users-module.no-lesson-assigned') }}
                </b-card>
              </b-col>
            </template>
            <template v-if="classTestAssignment.length === 0 && studentAssignment.length === 0 && classRoomAssignment.length === 0 && followUpAssignment.length === 0">
              <b-col
                class="no-lesson-large"
              >
                <b-card>
                  {{ $t('users-module.no-lesson-assigned') }} ssss
                </b-card>
              </b-col>
            </template>
            <template v-for="assignment,aIndex of allAssignments">
              <b-col v-if="assignment.data.length"
                     :key="aIndex"
                     md="4"
              >
                <div class="card mb-2">
                  <div class="card-body py-1">
                    <h4 class="mb-0">
                      {{ assignment.text }}
                    </h4>
                  </div>
                </div>
                <b-card v-for="item,index of assignment.data"
                        :key="index"
                        role="button"
                        @click="openLesson(item)"
                >
                  <LessonCard
                    :item="item"
                  />

                </b-card>
              </b-col>
            </template>
            <b-col
              md="4"
              class="large-followup"
            >
              <div class="card mb-2">
                <div class="card-body py-1">
                  <h4 class="mb-0">
                    {{ $t('follow-up-lessons') }}
                  </h4>
                </div>
              </div>
              <b-card v-for="item,index of followUpAssignment"
                      :key="index"
                      role="button"
                      @click="openLesson(item)"
              >
                <LessonCard
                  :item="item"
                />
              </b-card>
              <b-card v-if="!followUpAssignment.length">
                {{ $t('no-review-lessons') }}
              </b-card>
            </b-col>
            <b-col
              md="4"
              class="large-followup"
            >
              <div class="card mb-2">
                <div class="card-body py-1">
                  <h4 class="mb-0">
                    {{ $t('calendar-module.class-test') }}
                  </h4>
                </div>
              </div>
              <b-card v-for="item,index of classTestAssignment"
                      :key="index"
                      role="button"
                      @click="openLesson(item)"
              >
                <LessonCard
                  :item="item"
                />
              </b-card>
              <b-card v-if="!classTestAssignment.length">
                {{ $t('no-class-tests') }}
              </b-card>
            </b-col>
          </b-row>
        </b-tab>
        <b-tab
          v-if="followUpAssignment.length > 0 "
          :title="$t('follow-up-lessons')"
        >
          <b-row>
            <b-col
              md="4"
            >
              <b-card v-for="item,index of followUpAssignment"
                      :key="index"
                      role="button"
                      @click="openLesson(item)"
              >
                <LessonCard
                  :item="item"
                />
              </b-card>
            </b-col>
          </b-row>
        </b-tab>
        <b-tab
          v-if="classTestAssignment.length > 0 "
          :title="$t('calendar-module.class-test')"
        >
          <b-row>
            <b-col
              md="4"
            >
              <b-card v-for="item,index of classTestAssignment"
                      :key="index"
                      role="button"
                      @click="openLesson(item)"
              >
                <LessonCard
                  :item="item"
                />
              </b-card>
            </b-col>
          </b-row>
        </b-tab>
      </b-tabs>

    </BOverlay>
    <template v-else>
      <b-row>
        <b-col>
          <b-card>
            {{ $t('users-module.no-lesson-assigned') }}
          </b-card>
        </b-col>
      </b-row>
    </template>

  </div>
</template>
<script setup>
import {
  BRow, BCol, BCard, BTabs, BTab, BOverlay,
} from 'bootstrap-vue'
import { onMounted, getCurrentInstance, computed } from 'vue'
import i18n from '@/libs/i18n'
import useLessonDashboard from './useLessonDashboard'
import Statistics from './Statistics.vue'
import LessonCard from './components/LessonCard.vue'

const {
  getDashboard,
  today,
  studentAssignment,
  classRoomAssignment,
  hasNoAssignment,
  statisticsData,
  followUpAssignment,
  isProcessing,
  classTestAssignment,
} = useLessonDashboard()

onMounted(() => {
  getDashboard()
})

const root = getCurrentInstance().proxy.$root

const allAssignments = computed(() => ([
  {
    text: i18n.tc('users-module.your-assignment'),
    data: studentAssignment.value,
  },
  {
    text: i18n.tc('users-module.classroom-assignment'),
    data: classRoomAssignment.value,
  },
]))

// eslint-disable-next-line consistent-return
const openLesson = event => {
  if (event.is_locked) {
    return null
  }
  const self = root.AUTH_USER()
  const { lesson } = event
  if (lesson.completed_at) {
    root.$router.push({
      name: `${self.usertype}-course-result`,
      params: {
        classId: event.class_id,
        id: lesson.id,
      },
      query: {
        lesson_id: lesson.id,
        student_id: self.id,
      },
    })
  } else {
    // const route = root.$router.resolve({
    //   name: `${self.usertype}-lesson`,
    //   params: { id: event.lesson_id, classId: event.class_id },
    //   query: {
    //     event_id: event.id,
    //   },
    // })
    // window.open(route.href, '_blank')
    root.$router.push({
      name: `${self.usertype}-lesson`,
      params: { id: event.lesson_id, classId: event.class_id },
      query: {
        event_id: event.id,
      },
    })
  }
}

</script>
<style>
.lesson-image {
  min-width: 100px;
  max-width: 100px;
  height: 100px !important;
}
.lesson-tabs .nav {
  display: none;
}
.detail-responsive .calendar {
  display:inline;
}
.no-lesson-small {
  display: none;
}
@media(max-width:1200px) and (min-width:940px) {
 .detail-responsive{
  /* padding-left: 45px !important */
 }
 .lesson-image {
    min-width: 80px !important;
    max-width: 80px !important;
    height: 80px !important;
  }
}
@media(max-width:940px) and (min-width:840px) {
  .detail-responsive{
    /* padding-left: 50px !important; */
  }
  .lesson-image {
    min-width: 65px !important;
    max-width: 65px !important;
    height: 65px !important;
  }
  .detail-responsive .class-detail {
    font-size:10px;
    white-space: break-spaces;
    text-align: left;
  }
  .detail-responsive .calendar {
    font-size:10px;
  }
  .detail-responsive h4{
    font-size: 15px;
  }
  .detail-responsive h6{
    font-size: 13px;
  }
}
@media(max-width:840px) and (min-width:768px) {
  .lesson-completed-badge {
    width: 25px;
    height: 25px;
  }
  .lesson-image {
    min-width: 60px !important;
    max-width: 60px !important;
  }
  .detail-responsive{
    /* padding-left: 50px !important; */
  }
  .detail-responsive .class-detail {
    font-size:10px;
    white-space: break-spaces;
    text-align: left;
  }
  .detail-responsive .calendar {
    font-size:10px;
  }
  .detail-responsive h4{
    font-size: 15px;
  }
  .detail-responsive h6{
    font-size: 13px;
  }
  .detail-responsive .align-items-baseline span.badge{
    margin-left:0px !important;
    font-size: 11px;
  }
}
@media(max-width:768px) {
  .lesson-completed-badge {
    width: 25px;
    height: 25px;
  }
  .lesson-image {
    min-width: 100% !important;
    max-width: 100% !important;
    height: unset !important;
    text-align:center;
  }
  .detail-responsive{
    /* padding-left: 50px !important; */
  }
  .detail-responsive h4{
    font-size: 15px;
  }
  .detail-responsive h6{
    font-size: 13px;
  }
  .detail-responsive .align-items-baseline span.badge{
    margin-left:0px !important;
    font-size: 11px;
  }
  .lesson-tabs .nav {
    display: inline-flex;
  }
  .lesson-tabs .nav  li{
    background-color: #fff;
    border-radius: 5px;
    margin-right: 5px;
  }
  .large-followup{
    display:none;
  }
  .no-lesson-small {
    display: inline;
  }
  .no-lesson-large {
    display: none;
  }
}
</style>
