import useJwt from '@/auth/jwt/useJwt'
import { ref } from 'vue'

export default function useLessonDashboard() {
  const today = ref(null)
  const studentAssignment = ref([])
  const classRoomAssignment = ref([])
  const hasNoAssignment = ref(false)
  const statisticsData = ref(null)
  const followUpAssignment = ref([])
  const classTestAssignment = ref([])
  const isProcessing = ref(false)

  const getDashboard = () => {
    isProcessing.value = true
    useJwt.getStudentTableData('lesson-dashboard').then(response => {
      today.value = response.data.data.today
      const { events } = response.data.data
      const arrangedEvents = [
        ...events.filter(i => !i.is_locked),
        ...events.filter(i => i.is_locked),
      ]
      hasNoAssignment.value = !arrangedEvents.length && !response.data.data.class_tests.length
      studentAssignment.value = arrangedEvents.filter(i => i.assignment_type === 'private' && i.event_type !== 'follow_up')
      classRoomAssignment.value = arrangedEvents.filter(i => i.assignment_type === 'public' && i.event_type !== 'follow_up')
      followUpAssignment.value = arrangedEvents.filter(i => i.event_type === 'follow_up')
      const classTests = response.data.data.class_tests
      classTestAssignment.value = [
        ...classTests.filter(i => !i.is_locked),
        ...classTests.filter(i => i.is_locked),
      ]
      classTestAssignment.value = classTests
      statisticsData.value = response.data.data.statistics
    }).finally(() => {
      isProcessing.value = false
    })
  }

  return {
    getDashboard,
    isProcessing,
    today,
    studentAssignment,
    classRoomAssignment,
    followUpAssignment,
    hasNoAssignment,
    statisticsData,
    classTestAssignment,
  }
}
